$(document).ready(function () {
    // Shrink long holds action buttons
    if ($('.js-holdAction').first().text().length > 30) {
        $('.js-bottomButton').addClass('Holds-wideButton');
    }

    // Shrink long prerelease date text
    var prereleaseDate = $('.js-prereleaseDate');
    if (prereleaseDate.first().text().length > 50) {
        prereleaseDate.css('font-size', '9pt');
    }
});

/**
 * Send suspended hold request
 * @param mediaId
 */
window.suspendHold = function (mediaId, days = 0) {
    $.ajax({
        url: window.url(window.routes.mediaSuspendHold, { mediaId: mediaId }),
        type: 'PUT',
        data: {
            numberOfDays: featureManager.isEnabled('holdRedelivery')
                ? days
                : $('#select-suspension').val(), // Number of days set by user
        },
        success: function (data) {
            if (!featureManager.isEnabled('holdRedelivery')) {
                $('#suspension-modal').foundation('reveal', 'close');
                window.displaySuspendDate(
                    mediaId,
                    $('#select-suspension').val()
                );
            } else {
                window.displaySuspendDate(mediaId, days);
            }

            window.changeMenuOption(mediaId, true);
        },
        error: function (jqXHR, textStatus, errorThrown) {
            var response = JSON.parse(jqXHR.responseText);
            if (featureManager.isEnabled('holdRedelivery')) {
                $('.close-reveal-modal').click();
            } else {
                $('#suspension-modal').foundation('reveal', 'close');
            }

            if (response.errorCode && toaster) {
                var link =
                    '<a class="bold" href="' +
                    window.url(window.routes.supportMembers) +
                    '" tabindex="0">' +
                    window.html('pleaseContactSupport') +
                    '</a>';
                toaster.push(
                    '<strong>' +
                        window.html('genericError') +
                        '</strong> ' +
                        window.text('error.' + response.errorCode, {
                            link: link,
                        }),
                    'error'
                );
            } else if (response.sessionExpired) {
                window.globalEvents.trigger('user:session:expired');
            }
        },
    });
};

/**
 * Send suspended hold removal request
 * @param mediaId
 */
window.removeSuspendedHold = function (mediaId) {
    // Remove this when holdRelivery is no longer flagged
    // and replace the calls with removeSuspension(mediaId)
    $.ajax({
        url: window.url(window.routes.mediaRemoveHoldSuspension, {
            mediaId: mediaId,
        }),
        type: 'DELETE',
        success: function (data) {
            // Change any UI components here
            if (featureManager.isEnabled('holdRedelivery')) {
                $('.autoSuspendContainer').remove();
            }

            $('#suspendDate-' + mediaId).remove();
            window.changeMenuOption(mediaId, false);
        },
        error: function (jqXHR, textStatus, errorThrown) {
            $('#remove-suspension-modal').foundation('reveal', 'close');
        },
    });
};

window.callToRemoveSuspension = function (mediaId) {
    return new Promise((resolve, reject) =>
        $.ajax({
            url: window.url(window.routes.mediaRemoveHoldSuspension, {
                mediaId: mediaId,
            }),
            type: 'DELETE',
        })
            .done((response) => resolve(response))
            .fail((xhr, status, err) => reject(err))
    );
};

window.removeSuspension = function (mediaId) {
    let promise = window.callToRemoveSuspension(mediaId);

    promise
        .then((res) => {
            // Change any UI components here
            $('.autoSuspendContainer').remove();

            $('#suspendDate-' + mediaId).remove();
            window.changeMenuOption(mediaId, false);
        })
        .catch(() => {
            if (toaster) {
                toaster.push(window.text('errorOccurred'), 'error');
            }
            $('#remove-suspension-modal').foundation('reveal', 'close');
        });
};

window.alterSuspendButton = function (that) {
    var mediaId = $(that).data('media-id');

    if (!featureManager.isEnabled('holdRedelivery')) {
        $('#suspend-hold').attr(
            'onclick',
            'window.suspendHold(' + mediaId + ')'
        );
        $('#remove-suspend').attr(
            'onclick',
            'window.removeSuspendedHold(' + mediaId + ')'
        );
        $('.mediaTitle')
            .text($(that).data('title'))
            .attr('aria-label', $(that).data('title'));

        if ($(that).attr('data-button') == 'edit-hold') {
            $('.remove-suspension-button').show();
            var suspendPeriod = $(that).attr('data-suspend-period');
            $('#select-suspension').val(suspendPeriod);
        } else {
            $('.remove-suspension-button').hide();
        }
    }
};

$('.title-contents').on(
    'click',
    'a[data-reveal-id="suspension-modal"]',
    function () {
        window.alterSuspendButton(this);
    }
);

$('a[data-reveal-id="remove-suspension-modal"]').on('click', function () {
    var mediaId = $(this).data('media-id');
    featureManager.isEnabled('holdRedelivery')
        ? $('#remove-suspend').attr(
              'onclick',
              'window.removeSuspension(' + mediaId + ')'
          )
        : $('#remove-suspend').attr(
              'onclick',
              'window.removeSuspendedHold(' + mediaId + ')'
          );
    $('.mediaTitle').text($(this).data('title'));
});

window.displaySuspendDate = function (mediaId, days) {
    var date = addDays(days);
    var suspendedUntilDate = window.html('accountHolds.suspendedUntil', {
        date: date,
    });
    if ($('#suspension-container-' + mediaId).length) {
        // element exists
        $('#suspendDate-' + mediaId).remove();
        $('#suspension-container-' + mediaId).append(
            '<h3 id="suspendDate-' +
                mediaId +
                '" class="header-xxxsmall"><span class="date-text">' +
                suspendedUntilDate +
                '</span></h3>'
        );
    } else {
        var container = $(
            'div.title-container.' +
                mediaId +
                ' div.title-contents div.title-details-container'
        );
        var lastElem = container.children('div.holds-explanation-container');

        var dateContainer =
            '<div class="suspension-date" id="suspension-container-' +
            mediaId +
            '">' +
            '<h3 id="suspendDate-' +
            mediaId +
            '" class="header-xxxsmall"><span class="date-text">' +
            suspendedUntilDate +
            '</span></h3>' +
            '</div>';
        lastElem.after(dateContainer);
    }
};

window.changeMenuOption = function (mediaId, suspended) {
    if (suspended) {
        // If title is suspended, add "Remove suspension" option
        var suspendPeriod = +$('#select-suspension').val();

        $('a#edit-hold-' + mediaId).attr('data-suspend-period', suspendPeriod);

        $('a#suspend-' + mediaId)
            .html(
                "<i class='icon icon-calendar' aria-hidden='true'></i> Edit hold"
            )
            .attr({
                id: 'edit-hold-' + mediaId,
                'aria-label': window.html('accountHolds.editHold'),
                'data-button': 'edit-hold',
                'data-suspend-period': suspendPeriod,
            });
    } else {
        $('a#edit-hold-' + mediaId)
            .html(
                "<i class='icon icon-calendar' aria-hidden='true'></i> Suspend hold"
            )
            .attr({
                'aria-label': window.html('accountHolds.suspendHold'),
                'data-button': 'suspend-hold',
                id: 'suspend-' + mediaId,
            });
    }
};

// Target select box problems for old Android devices
if (bowser.android && parseInt(bowser.osversion, 10) < 4) {
    // Change the suspension modal position to "fixed" to allow the select to work
    $('#suspension-modal')
        .on('opened.fndtn.reveal', function () {
            $('#suspension-modal').css('position', 'fixed');
        })
        .on('closed.fndtn.reveal', function () {
            // Change the modal position back to absolute so it displays correctly the next time it's opened
            $('#suspension-modal').css('position', 'absolute');
        });

    // Hide and show the parent container of the select to re-render and show the user's selection
    // For whatever reason, $(this).parent() does not work here
    $('select').on('change', function () {
        $('.suspend-select-container').hide();
        setTimeout(function () {
            $('.suspend-select-container').show();
        }, 0);
    });
}
